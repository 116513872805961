<template>
  <div id='faq'>
    <van-nav-bar title="常见问题" left-arrow right-text="产品"  @click-left="$router.go(-1)" @click-right="showProduct"/>
    <div class="search">
      <van-search v-model="searchKey" background="#f4f4f4" placeholder="请输入搜索关键词" @input="search"/>
    </div>
    <div class="faqlist">
      <van-row>
        <van-col span="8" class="col-fc">
          <p class="c-t">问题类型</p>
          <p v-for="item in faqCategories" :key="item.id" @click="handleClickFaqCategory(item.id)">{{item.name}}</p>
        </van-col>
        <van-col span="1" class="col-span"></van-col>
        <van-col span="15" class="col-faq">
          <p v-for="item in faqs" :key="item.id" @click="godis(item.id)">{{item.title}}</p>
        </van-col>
      </van-row>
    </div>
    <van-popup v-model="showPop" position="right" :style="{ height: '100%', width: '40%' }">
      <van-collapse accordion v-model="activeProductCategory" @change="handleClickProductCategory">
        <van-collapse-item v-for="item in productCategories" :key="item.id" :title="item.name" :name="item.name">
          <p v-for="item in products" :key="item.id" @click="setProductTranslate(item.id)">{{item.name}}</p>
        </van-collapse-item>
      </van-collapse>
    </van-popup>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabPosition: 'left',
      apiUrl: 'https://api-site.huami.com/',
      productCategories: [],
      activeProductCategory: '',
      products: [],
      faqCategories: [],
      faqs: [],
      faqCategoryId: null,
      productTranslateId: null,
      searchKey: null,
      showPop: false
    }
  },
  created () {
    this.$http.defaults.headers.common['X-SITE'] = 'amazfit'
    this.$http.defaults.headers.common['X-LOCALE'] = 'cn'
    this.loadProductCategories()
    this.loadFaqCategories()
  },
  methods: {
    loadProductCategories () {
      this.$http.get(this.apiUrl + 'v1/product-categories').then(function (res) {
        this.productCategories = res.data
      }.bind(this)).catch(function (err) {
        this.$toast.fail(err.response.data.message)
      }.bind(this))
    },
    loadProducts (id) {
      this.$http.get(this.apiUrl + 'v1/product-translates', { params: { 'product_category_id': id, 'page': 1, 'per-page': 200 } }).then(function (res) {
        this.products = res.data
      }.bind(this)).catch(function (err) {
        this.$toast.fail(err.response.data.message)
      }.bind(this))
    },
    loadFaqCategories () {
      this.$http.get(this.apiUrl + 'v1/faq-categories', { params: { 'page': 1, 'per-page': 100 } }).then(function (res) {
        this.faqCategories = res.data
        let fc = this.faqCategories[0]
        this.activeFaqCategory = fc.name
        this.faqCategoryId = fc.id
        this.loadFaqs()
      }.bind(this)).catch(function (err) {
        this.$toast.fail(err.response.data.message)
      }.bind(this))
    },
    loadFaqs () {
      this.$http.get(this.apiUrl + 'v1/faqs', { params: { 'faq_category_id': this.faqCategoryId, 'product_translate_id': this.productTranslateId, 'title': this.searchKey, 'page': 1, 'per-page': 10 } }).then(function (res) {
        this.faqs = res.data
      }.bind(this)).catch(function (err) {
        this.$toast.fail(err.response.data.message)
      }.bind(this))
    },
    handleClickProductCategory (name) {
      this.products = []
      this.productCategories.forEach(element => {
        if (element.name === name) {
          let id = element.id
          this.loadProducts(id)
        }
      })
    },
    handleClickFaqCategory (id) {
      this.faqCategoryId = id
      this.loadFaqs()
    },
    showProduct () {
      this.showPop = true
    },
    setProductTranslate (id) {
      this.productTranslateId = id
      this.loadFaqs()
      this.showPop = false
    },
    godis (id) {
      this.$router.push({ path: '/services/faq-detail', query: { id: id } })
    },
    search () {
      this.faqCategoryId = null
      this.productTranslateId = null
      this.loadFaqs()
    }
  }
}
</script>

<style scoped>
#faq {
  height: 100vh;
  background: #f4f4f4;
}

#faq .faqlist {
  padding: 0rem 0.32rem
}

#faq .col-span {
  height: 85vh;
  background: #f4f4f4;
}

#faq .col-fc {
  height: 85vh;
  background:white;
  border-radius: 10px;
}

#faq .col-fc p {
  padding-left: 2vw;
  text-align: left;
  font-size: 14px;
}

#faq .col-fc .c-t {
  font-weight: bold;
}

#faq .col-faq {
  height: 85vh;
  background:white;
  border-radius: 10px;
}

#faq .col-faq p {
  text-align: left;
  padding-left: 10px;
  font-size: 14px;
  font-weight: bold;
}
</style>
